import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const EventsProposal = () => {
  return (
    <div className="EventsProposal">
      <div className="EventsProposal-banner">
        <img
          className="img-fluid"
          alt="banner"
          src={require("../../../../Assets/images/custom-design/event/banner.png")}
        />
        <div className="EventsProposal-banner-text">
          <h3>I Organize Your Marriage Proposal</h3>
        </div>
      </div>

      <div className="EventsProposal-1">
        <Container>
          <h3 className="text-center">
            An Unforgettable Moment Deserves Thoughtful Planning
          </h3>
          <Row>
            <Col xs={12} md={6}>
              <p>
                <b>Hi, I'm Diana Spinosa,</b> Head of the Events Department at{" "}
                <b>Spinosa Compromiso,</b> and the person who will be by your
                side to ensure your marriage proposal is truly unforgettable.
              </p>
              <p>
                Many men — and women too — come to our jewelry store excited and
                eager to choose the perfect engagement ring
              </p>
              <p>
                And almost always, they bring a thousand questions — not just
                about whether the ring will be to their partner's liking, but
                also about how to organize such a meaningful moment as the
                proposal itself.
              </p>
              <p>
                Where should it happen? When should I give the ring? How can I
                surprise them?
              </p>
              <p>That's where I come in.</p>
              <p>
                If you want to show your person just how much they mean to you —
                not only by giving them a beautiful jewel, but by living a
                magical moment full of emotion, style and surprise — let me take
                care of everything.
              </p>
            </Col>
            <Col xs={12} md={6}>
              <p>
                I'll guide you, advise you and organize each detail so your
                proposal reflects your love story and becomes a scene worthy of
                a movie.
              </p>
              <p>
                All you have to focus on is the big question: “Will you marry
                me?”
              </p>
              <p>
                The location, the music, the flowers, the ring delivery, the
                photos or video… I'll handle every single detail so the memory
                stays with you both forever.
              </p>
              <p>
                Every couple is unique, so I adapt to you —and your budget. And
                if you're not sure where to begin, let me inspire you with a few
                real ideas :
                <ul>
                  <li>
                    A ride through Marbella's old town in a <b>classic car</b>,
                    ending in a surprise by the sea
                  </li>
                  <li>
                    An <b>intimate dinner on the beach,</b> with champagne and
                    strawberries at sunset
                  </li>
                  <li>
                    A proposal on a boat, surrounded by candles and soft lights
                  </li>
                  <li>
                    A ring hidden inside a <b>bouquet</b>, a special dessert, or
                    even… presented mid-air on a <b>helicopter flight</b>.
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
          <p className="text-center font-weight-bold">
            All possibilities are in your hands. And I'll be here to make sure
            every step is perfect.
          </p>
        </Container>
      </div>

      {/* <div className="EventsProposal-2">
        <Container>
          <h3>
            Where should it happen? When should I give the ring? How can I
            surprise them?
          </h3>
          <p>
            Hi, I’m Diana Spinosa, Head of the Events Department at Spinosa
            Compromiso, and the person who will be by your side to ensure your
            marriage proposal is truly unforgettable.
          </p>
          <p>
            If you want to show your person just how much they mean to you — not
            only by giving them a beautiful jewel, but by living a magical
            moment full of emotion, style and surprise — let me take care of
            everything.
          </p>
          <p>
            I’ll guide you, advise you and organize each detail so your proposal
            reflects your love story and becomes a scene worthy of a movie.
          </p>
          <h4>
            All you have to focus on is the big question: “Will you marry me?”
          </h4>
          <p>
            The location, the music, the flowers, the ring delivery, the photos
            or video… I’ll handle every single detail so the memory stays with
            you both forever.
          </p>
        </Container>
      </div>

      <div className="EventsProposal-3">
        <Container>
          <h3>
            Every couple is unique, so I adapt to you — and your budget. And if
            you’re not sure where to begin, let me inspire you with a few real
            ideas:
          </h3>
          <ul>
            <li>
              A ride through Marbella’s old town in a Classic Car, ending in a
              surprise by the sea
            </li>
            <li>
              An Intimate Dinner on the Beach, with champagne and strawberries
              at sunset
            </li>
            <li>A proposal on a Boat, surrounded by candles and soft lights</li>
            <li>
              A ring hidden inside a Bouquet, a special dessert, or even…
              presented mid-air on a Helicopter Flight
            </li>
          </ul>
          <p>
            All possibilities are in your hands. And I’ll be here to make sure
            every step is perfect.
          </p>
        </Container>
      </div> */}

      <div className="EventsProposal-4">
        <Container>
          <div className="EventsProposal-4__content">
            <h3>Proposal Packages</h3>
            <p>
              Each love story is one of a kind — and that’s why all our proposal
              packages are fully customizable.
            </p>
            <p>
              These packs are just a starting point: a spark to help you imagine
              what your proposal could be like. From intimate gestures to grand
              experiences, I’ll tailor each detail to reflect your essence —and
              your budget.
            </p>
          </div>
        </Container>
      </div>

      <div className="EventsProposal-5">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={6}>
              <img
                className="img-fluid"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/event/1.png")}
              />
            </Col>
            <Col xs={12} md={6}>
              <h3>Emotion Pack</h3>
              <h4><i>Subtle surprises, heartfelt impact</i></h4>
              <p>
                Perfect for those who believe the smallest gestures create the
                deepest memories. A private, delicate proposal where every
                detail speaks for itself. If you're looking to surprise with
                elegance and emotion, without overdoing it but with all the
                meaning, this is for you.
              </p>
              <h4>Includes:</h4>
              <ul>
                <li>Event coordination</li>
                <li>
                  Special ring delivery (dessert, flowers or pre-agreed
                  surprise)
                </li>
                <li>Essential, refined decoration</li>
                <li>Local venue suggestions</li>
                <li>Personalized support</li>
                <li>Movil  Pictures and Video</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="EventsProposal-5">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={6}>
              <h3>Essence Pack</h3>
              <h4><i>Spontaneous romance, captured forever</i></h4>
              <p>
                For those who believe a single image says it all — and some
                glances deserve to be remembered. If you want to relive this
                moment again and again, this is your pack. It adds that visual
                magic that turns a memory into a legacy.
              </p>
              <p>
                The ring will appear inside a surprise balloon, and the moment
                will be captured with a professional photo shoot.
              </p>
              <h4>Includes everything from the Emotion Pack, plus:</h4>
              <ul>
                <li>Flower bouquet</li>
                <li>Surprise moment coordination</li>
                <li>Professional photo shoot</li>
              </ul>
            </Col>
            <Col xs={12} md={6} className="order-first order-md-last">
              <img
                className="img-fluid"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/event/2.png")}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="EventsProposal-5">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={6}>
              <img
                className="img-fluid"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/event/3.jpg")}
              />
            </Col>
            <Col xs={12} md={6}>
              <h3>Eternity Pack</h3>
              <h4><i>A romantic setting, a memory for life</i></h4>
              <p>
                Ideal for couples seeking a special, soulful and intimate
                proposal. I’ll organize a beautifully styled picnic in a
                romantic corner of Marbella, complete with elegant décor,
                champagne, flowers with a message and a scene you’ll never
                forget.
              </p>
              <p>
                The ring will appear inside a surprise balloon, and the moment
                will be captured with a professional photo shoot.
              </p>
              <h4>Includes:</h4>
              <ul>
                <li>Event coordination</li>
                <li>Picnic with decoration</li>
                <li>Champagne, appetizers and special cake</li>
                <li>Bouquet with romantic message</li>
                <li>Surprise balloon with ring inside</li>
                <li>Professional Photos and Video</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="EventsProposal-5">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={6}>
              <h3>Legend Pack</h3>
              <h4><i>A proposal that will be remembered forever</i></h4>
              <p>
                If you dream of a proposal that doesn't just surprise — but
                truly moves them— this is your pack.
              </p>
              <p>
                Fancy a Luxury Classic Car ride? Or a Sunset Yacht Experience?
                Or perhaps you'd love to see Marbella from above in a
                Helicopter? Whatever you imagine, I'll make it happen.
              </p>
              <p>
                This pack includes a romantic setting with Personalized Décor,
                An Exclusive Picnic, Champagne and Appetizers, and a sweet
                surprise — a Special Cake that hides the Ring inside.
              </p>
              <p>
                And of course, everything will be beautifully immortalized in a
                Professional Photo Shoot — a love story worth remembering.
              </p>
              <h4>Includes:</h4>
              <ul>
                <li>
                  Ride in a chosen vehicle (luxury car, yacht, or helicopter)
                </li>
                <li>Exclusive location</li>
                <li>Personalized floral décor and ambiance</li>
                <li>Picnic with champagne, appetizers and special cake</li>
                <li>Complete event coordination</li>
                <li>Professional photo shoot photos and video</li>
              </ul>
            </Col>
            <Col xs={12} md={6} className="order-first order-md-last">
              <img
                className="img-fluid"
                alt="banner"
                src={require("../../../../Assets/images/custom-design/event/4.png")}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="EventsProposal-4">
        <Container>
          <div className="EventsProposal-4__content">
            <h3>Can you picture the moment already?</h3>
            <p>
              If this sparked your imagination, don't let it slip away. Click
              the contact button or open the chat on your screen and tell me
              your story.
            </p>
            <p>
              I'll be delighted to help you plan a proposal as extraordinary as
              the love you share.
            </p>
            <h4>Your story deserves an unforgettable beginning.</h4>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default EventsProposal;
